import { isEqual } from "lodash";
import { uuid } from "vue-uuid";
import RevenueCode from "store/models/RevenueCode";
import Actions from "modules/actions/revenue-code-actions";

const actions = new Actions();

const REVENUE_PAYMENT_CODE = "PAYMENT";
const REVENUE_BILLER_CODE = "BILLERR";

export default {
  name: "AccessionAdjustmentModal",
  props: {
    modalVisibility: {
      type: Boolean,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      render: true,
      isLoading: false,
      revenueCodeModel: RevenueCode,
      modelQueryOptions: {
        relativeUrl: "/revenue-code/get-all-revenue-code",
        queryName: "vbsRevenueCodes",
        filter: {
          enum_codeType: "REVENUE_CODE",
        },
        limitData: ["code", "totalCount"],
      },
      discardModalVisibility: false,
      transactionObj: null,
      transactionObjCopy: null,
      date: [],
      details: {
        cols: [
          {
            header: "Billed Amount",
            data: "",
            colSpan: 3,
          },
          {
            header: "Current Balance",
            data: "",
            colSpan: 3,
          },
        ],
      },
      revenueCodeOptions: [],
      suggestedDefaultRevenueCode: null,
      rules: {
        required: (index, type, val) => {
          if (this.fieldShouldBeRequired(index, type)) {
            if (
              val === null ||
              val === undefined ||
              val.length === 0 ||
              val === ""
            ) {
              return "This field is required";
            }
          }
          return true;
        },
        paidAmount: (index, val, currentBal) => {
          if (currentBal < 0 && val < currentBal) {
            return "Paid Amount should not be greather than the current balance";
          }
          return true;
        },
        date: (index, type, val) => {
          if (this.fieldShouldBeRequired(index, type))
            return !val || this.isValidDate(val) || "Invalid date.";
          return true;
        },
      },
    };
  },
  computed: {
    __ModalVisibility: {
      get() {
        return this.modalVisibility;
      },
      set(value) {
        this.$emit("update:modalVisibility", value);
      },
    },
    submitBtnDisabled() {
      const formRows = this.transaction.adjustments.filter(i => i.isLocallyAdded);
      const newRows = this.transactionObj.adjustments.filter(i => i.isLocallyAdded);
      const isDisabled = isEqual(newRows, formRows);
      return isDisabled;
    },
    fieldShouldBeRequired() {
      return (index, type) => {
        if (
          this.transactionObj.adjustments[index].paidAmount?.length &&
          parseFloat(this.transactionObj.adjustments[index].paidAmount) > 0
        ) {
          return true;
        } else {
          if (
            this.transactionObj.adjustments[index].adjAmount?.length &&
            parseFloat(this.transactionObj.adjustments[index].adjAmount) > 0
          ) {
            if (
              type.toUpperCase() == "CHECKNUMBER" ||
              type.toUpperCase() == "CHECKDATE"
            )
              return false;
            else return true;
          }
          return false;
        }
      };
    },
    shouldDisableCheckFields() {
      return (paidAmount, adjAmount) =>
        adjAmount?.length > 0 && !paidAmount?.length;
    },
    isZeroOrNegativeBalance() {
      const { rawBalance } = this.transaction;
      return rawBalance <= 0;
    },
  },
  methods: {
    async validatePaidAdjustments() {
      let tableFormValidate = await Promise.all(
        [...this.transactionObj.adjustments].map(async (item, index) => {
          let rows = await this.$refs[`paidAmount-${index}`][0].validateAsync();
          return rows;
        })
      );
      return tableFormValidate.filter((bool) => !bool).length === 0;
    },
    onAmountChange(value, param) {
      if (this.suggestedDefaultRevenueCode) {
        const { field, index } = param;

        this.render = false;
        let findCode;
        if (field == "paidAmount") {
          findCode =
            value.paidAmount?.length &&
            (!value.adjAmount?.length || value.adjAmount?.length)
              ? REVENUE_PAYMENT_CODE
              : REVENUE_BILLER_CODE;
        } else {
          findCode =
            value.adjAmount?.length &&
            (value.paidAmount?.length || !value.paidAmount?.length)
              ? REVENUE_BILLER_CODE
              : REVENUE_PAYMENT_CODE;
        }

        console.log(findCode);

        const { result: suggestCode } = this.suggestedDefaultRevenueCode.find(
          (item) => item.default === findCode
        );
        const { paidAmount, adjAmount } =
          this.transactionObj.adjustments[index];

        if (this.shouldDisableCheckFields(paidAmount, adjAmount)) {
          this.transactionObj.adjustments[index].checkNumber = "";
          this.transactionObj.adjustments[index].checkDate = "";
          this.date[index].value = "";
        }

        Object.assign(
          this.transactionObj.adjustments[index].revenueCode,
          suggestCode[0]
        );

        this.$nextTick(() => {
          this.render = true;
        });
      }
    },

    addAdjustment() {
      this.date.push({
        menu: false,
        value: "",
      });

      this.transactionObj.adjustments.push({
        id: uuid.v1(),
        isLocallyAdded: true,
        paidAmount: 0,
        adjAmount: 0,
        revenueCode: {
          id: null,
          code: "",
        },
        checkNumber: "",
        checkDate: "",
      });

      this.$nextTick(() => {
        let elmnt = this.$refs["form"].$el;
        elmnt.scrollTop = elmnt.scrollHeight;
      });
    },
    clearRevenueCode(index) {
      this.transactionObj.adjustments[index].revenueCode = {
        id: null,
        code: "",
      };
    },
    async removeAdjustment(index) {
      this.date.splice(index, 1);
      this.render = false;
      this.transactionObj.adjustments.splice(index, 1);

      this.$nextTick(() => {
        this.render = true;
      });

      await this.$refs.form.resetValidation();
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal(index, value) {
      this.transactionObj.adjustments[index].checkDate = this.formatDate(value);
      this.date[index].menu = false;
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";

      const [month, day, year] = date.split("/");
      return month && day && year
        ? `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        : null;
    },
    populateRevenueCode(index, e) {
      const { id, code } = e;

      this.transactionObj.adjustments[index].revenueCode.id = id;
      this.transactionObj.adjustments[index].revenueCode.code = code;
    },
    async submitAdjustments() {
      const validateForm = await this.$refs.form.validateAsync();
      const validatePaidAmounts = await this.validatePaidAdjustments();

      let totalPaidLocal = [...this.transactionObj.adjustments]
        .filter((item) => item.isLocallyAdded)
        .map((item) => item.paidAmount)
        .reduce((a, c) => {
          return parseFloat(a) + parseFloat(c);
        });

      if (totalPaidLocal < this.transactionObj.maxNegativeAmount) {
        this.showNotifyMessage({
          message:
            "Total negative paid amounts should not be greater the current balance",
          type: "danger",
        });

        return;
      }

      if (validateForm && validatePaidAmounts) {
        this.$emit("submitAdjustments", this.transactionObj);
        this.__ModalVisibility = false;
      }
    },
    discardAdjustments() {
      this.__ModalVisibility = false;
    },
    cancelAdjustmentSubmission() {
      if (!this.submitBtnDisabled) {
        this.discardModalVisibility = true;
        return;
      }
      this.__ModalVisibility = false;
    },

    async loadDefaultRevenueCode() {
      try {
        this.isLoading = true;
        const result = await Promise.all(
          [REVENUE_PAYMENT_CODE, REVENUE_BILLER_CODE].map(async (code) => {
            const defaultRevenueCodes = await actions.getAllRevenueCodes({
              filter: {
                enum_codeType: "REVENUE_CODE",
                code,
                enum_patternMatch: "EXACT",
                logicalOperator: "AND",
              },
              limitData: ["code", "id"],
              shouldNotReturnId: true,
            });
            return { default: code, result: defaultRevenueCodes };
          })
        );
        this.isLoading = false;

        this.suggestedDefaultRevenueCode = [...result];
      } catch (err) {
        this.isLoading = false;
      }
    },
  },
  async created() {
    const { balance, billedAmount } = this.transaction;

    this.details.cols[0].data = billedAmount;
    this.details.cols[1].data = balance;

    this.transactionObj = JSON.parse(JSON.stringify(this.transaction));

    const { adjustments } = this.transactionObj;

    if (adjustments?.length == 0) this.$nextTick(() => this.addAdjustment());

    this.date = this.transactionObj.adjustments
      ? this.transactionObj.adjustments.map((adjustment) => {
          return {
            menu: false,
            value: adjustment.checkDate?.length
              ? this.$options.filters.getTimeDate(
                  adjustment.checkDate,
                  "YYYY-MM-DD",
                  true,
                  null
                ).utc
              : "",
          };
        })
      : [];

    await this.loadDefaultRevenueCode();
  },
};
