import Actions from "modules/actions/client-invoice-actions";

const actions = new Actions();

export default {
  name: "Comments",
  props: {
    clientInvoiceId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    isDataLoading: false,
    isDisabled: false,
    isClearButtonDisabled: true,
    isDiscardModalVisible: false,
    initialDataLoading: false,
    notes: [],
    totalCount: 0,
    columns: [
      {
        name: "date",
        align: "left",
        text: "Date",
        field: "date",
      },
      {
        name: "user",
        align: "left",
        text: "User",
        field: "user",
      },
      {
        name: "comment",
        align: "left",
        text: "Comment",
        field: "comment",
      },
    ],
    formData: {
      note: "",
    },
  }),
  computed: {
    shoudlDisableAddNote() {
      return (
        this.isDisabled ||
        !this.formData.note.trim() ||
        this.formData.note.length < 1
      );
    },
  },
  methods: {
    async fetchNotes(pagination) {
      try {
        this.loading(true);
        const result = await actions.getAllClientInvoice({
          variables: {
            property: {
              clientInvoiceId: {
                type: "UUID!",
                value: this.clientInvoiceId,
              },
              ["notes.limit"]: {
                type: "Int",
                value: pagination?.limit || 10,
              },
              ["notes.offset"]: {
                type: "Int",
                value: pagination?.offset || 0,
              },
            },
          },
          limitData: [
            this.buildSubQuery("notes", [
              "notes",
              "created",
              this.buildSubQuery("createdBy", ["firstName", "lastName"]),
              "totalCount",
            ]),
          ],
        });
        if (result) {
          const { notes } = result;
          this.notes = notes.map((note) => ({
            date: note?.created
              ? this.$options.filters.getTimeDate(note?.created, "MM/DD/YYYY")
                  .zone
              : "",
            user: note?.createdBy
              ? `${note?.createdBy?.firstName} ${note?.createdBy?.lastName}`
              : "",
            comment: note?.notes || null,
          }));
          this.totalCount = notes[0]?.totalCount || 0;
        }
        this.loading(false);
      } catch (err) {
        this.loading(false);
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    clearTextArea() {
      if (!this.formData.note.trim()) {
        this.formData.note = "";
        return;
      }
      this.handleDiscardModalVisibility(true);
    },
    handleDiscardModalVisibility(isVisible) {
      this.isDiscardModalVisible = isVisible;
    },
    confirmDiscardChanges() {
      this.handleDiscardModalVisibility(false);
      this.formData.note = "";
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async addNote() {
      if (this.isDisabled || !this.formData.note.trim()) return;
      try {
        this.isDisabled = true;
        const { addNote } = await actions.addNoteToClientInvoice({
          variables: {
            property: {
              clientInvoiceId: {
                type: "UUID!",
                value: this.clientInvoiceId,
              },
              ["addNote.input"]: {
                type: "VbsClientInvoiceNotesInput!",
                value: {
                  notes: this.formData.note,
                },
              },
            },
          },
          limitData: [
            this.buildSubQuery("addNote", ["errors", "success"], false),
          ],
        });
        const { errors, success } = addNote;

        if (!success || errors?.length > 0) {
          const errorMesssage =
            errors.length > 0
              ? errors[0]
              : "Problem has occurred while saving Client Invoice comment.";
          throw errorMesssage;
        }
        this.formData.note = "";
        this.isDisabled = false;
        this.isClearButtonDisabled = true;
        this.showNotifyMessage({
          message: "Client Invoice comment saved.",
          type: "success",
        });
        this.fetchNotes();
      } catch (error) {
        this.showNotifyMessage({
          message: error,
          type: "danger",
        });
        this.isDisabled = false;
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetchNotes();
      this.initialDataLoading = false;
    });
  },
};
