import Actions from "modules/actions";
import RevenueCode from "store/actions/revenueCode";

const revenueCodeAction = new RevenueCode();

export default class RevenueCodeAction extends Actions {
  async getAllRevenueCodes(variables) {
    return revenueCodeAction.getAll(variables)
  }

  async getRevenueCodeDetails(variables) {
    return revenueCodeAction.get(variables)
  }

  async createRevenueCode(variables) {
    return revenueCodeAction.create(variables);
  }

  async updateRevenueCodeDetails(variables) {
    return revenueCodeAction.update(variables);
  }

  async activateRevenueCode(variables) {
    return await revenueCodeAction.activate(variables);
  }

  async deactivateRevenueCode(variables) {
    return await revenueCodeAction.deactivate(variables);
  }

  async getCodes(variables) {
    return revenueCodeAction.getCodes(variables)
  }
}
