import isEqual from "lodash/isEqual";

import Actions from "modules/actions/client-invoice-actions";
import Comments from "./Comments";
import AccessionAdjustmentModal from "modules/main/ClientsPhysicians/Clients/modals/AccessionAdjustmentModal";

const actions = new Actions();

export default {
  name: "ClientInvoiceTransaction",
  components: {
    Comments,
    AccessionAdjustmentModal,
  },
  data() {
    return {
      htmlString: "",
      isDataLoading: false,
      isSaving: false,
      isDisabled: false,
      initialDataLoading: false,
      invoiceTransactionAdjustments: null,
      modalVisibility: {
        draftCard: false,
        discardAdjustment: false,
        applyAdjustment: false,
        adjustment: false,
      },
      summary: {
        cols: [
          {
            header: "Invoice Number",
            data: null,
          },
          { header: "Date of Invoice", data: null },
          { header: "Total Billed", data: null },
          { header: "Total Paid", data: null },
          { header: "Total Adjustment", data: null },
          { header: "Current Balance", data: null },
        ],
      },
      summaryCopy: null,
      transaction: null,
      transactions: [],
      transactionsCopy: [],
      totalCount: 0,
      columns: [
        {
          name: "dateOfService",
          align: "left",
          text: "Date of Service",
          field: "dateOfService",
        },
        {
          name: "accession",
          align: "left",
          text: "Accession ID",
          field: "accession",
        },
        {
          name: "physicianName",
          align: "left",
          text: "Physician Name",
          field: "physicianName",
        },
        {
          name: "testId",
          align: "left",
          text: "Test ID",
          field: "testId",
        },
        {
          name: "billedAmount",
          align: "left",
          text: "Billed Amount",
          field: "billedAmount",
        },
        {
          name: "paidAmount",
          align: "left",
          text: "Paid Amount",
          field: "paidAmount",
          currency: {
            disabled: true,
            icon: "mdi-plus",
            returnValue: "self",
            click: val => this.onCurrencyFieldClick(val),
          },
        },
        {
          name: "adjAmount",
          align: "left",
          text: "Adj Amount",
          field: "adjAmount",
          currency: {
            disabled: true,
            icon: "mdi-plus",
            returnValue: "self",
            click: val => this.onCurrencyFieldClick(val),
          },
        },
        {
          name: "revenueCode",
          align: "left",
          text: "Rev/Adj Code",
          field: "revenueCode",
        },
        {
          name: "balance",
          align: "left",
          text: "Balance",
          field: "balance",
        },
        {
          name: "checkDetails",
          align: "left",
          text: "Check Details",
          field: "checkDetails",
        },
        {
          name: "transactionDate",
          align: "left",
          text: "Transaction Date",
          field: "transactionDate",
        },
      ],
    };
  },
  computed: {
    shouldDisableBtn() {
      return isEqual(this.transactions, this.transactionsCopy);
    },
    isDraft() {
      return this.summary?.cols[0]?.status === "DRAFT";
    },
  },
  methods: {
    async fetchTransactions(pagination) {
      try {
        this.loading(true);
        const result = await actions.getAllClientInvoice({
          variables: {
            property: {
              clientInvoiceId: {
                type: "UUID!",
                value: this.$route.params.id,
              },
              ["transactions.limit"]: {
                type: "Int",
                value: pagination?.limit || 10,
              },
              ["transactions.offset"]: {
                type: "Int",
                value: pagination?.offset || 0,
              },
            },
          },
          limitData: [
            "revisionId",
            "invoiceNumber",
            "invoiceDate",
            "totalBilled",
            "totalPaid",
            "totalAdjustment",
            "currentBalance",
            "status",
            this.buildSubQuery("transactions", [
              this.buildSubQuery("order", [
                "dateOfService",
                this.buildSubQuery("sample", ["sampleId"]),
              ]),
              "checkDate",
              "checkNumber",
              this.buildSubQuery("physician", ["firstName", "lastName"]),
              this.buildSubQuery("test", ["testCode"]),
              "billedAmount",
              "paidAmount",
              "adjAmount",
              this.buildSubQuery("revenueCode", ["code"]),
              "balance",
              "transactionDate",
              "totalCount",
              this.buildSubQuery("adjustments", [
                "paidAmount",
                "adjAmount",
                this.buildSubQuery("revenueCode", ["code"]),
                "checkNumber",
                "checkDate",
              ]),
            ]),
          ],
        });
        if (result) {
          const {
            currentBalance,
            invoiceDate,
            invoiceNumber,
            transactions,
            totalBilled,
            totalPaid,
            totalAdjustment,
            status,
          } = result;
          this.summary = {
            cols: [
              {
                colSpan: "3",
                header: "Invoice Number",
                data: invoiceNumber,
                status: status === "DRAFT" ? status : null,
                button:
                  status === "DRAFT"
                    ? {
                        label: "Discard Changes",
                        click: () => {
                          if (this.modalVisibility.draftCard) return;
                          this.modalVisibility.draftCard = true;
                        },
                      }
                    : "",
              },
              {
                header: "Date of Invoice",
                data: invoiceDate
                  ? this.$options.filters.getTimeDate(invoiceDate, "MM/DD/YYYY")
                      .zone
                  : "",
              },
              {
                header: "Total Billed",
                key: "totalBilled",
                data: this.getFormattedAmount(totalBilled),
                rawValue: totalBilled,
              },
              {
                header: "Total Paid",
                key: "totalPaid",
                data: this.getFormattedAmount(totalPaid),
                rawValue: totalPaid,
              },
              {
                header: "Total Adjustment",
                key: "totalAdjustment",
                data: this.getFormattedAmount(totalAdjustment),
                rawValue: totalAdjustment,
              },
              {
                header: "Current Balance",
                key: "currentBalance",
                data: this.getFormattedAmount(currentBalance),
                rawValue: currentBalance,
              },
            ],
          };
          this.transactions = transactions.map((transaction) => ({
            id: transaction.id,
            dateOfService: transaction?.order?.dateOfService || null,
            accession: transaction?.order?.sample?.sampleId || null,
            physicianName: transaction.physician
              ? `${transaction?.physician.firstName} ${transaction?.physician.lastName}`
              : "",
            testId: transaction?.test?.testCode || null,
            billedAmount: this.getFormattedAmount(transaction.billedAmount),
            paidAmount: transaction?.paidAmount || 0,
            adjAmount: transaction?.adjAmount || 0,
            revenueCode: transaction?.revenueCode?.code || null,
            maxNegativeAmount: transaction?.balance < 0 ? transaction.balance : 0,
            balance: this.getFormattedAmount(transaction.balance),
            rawBalance: transaction?.balance || 0,
            checkDetails: transaction?.checkNumber || "",
            transactionDate: transaction.transactionDate
              ? this.$options.filters.getTimeDate(
                  transaction.transactionDate,
                  "MM/DD/YYYY"
                ).zone
              : "",
            adjustments: transaction.adjustments
              ? transaction.adjustments.map((adjustment) => {
                  const {
                    id,
                    adjAmount,
                    checkDate,
                    checkNumber,
                    paidAmount,
                    revenueCode,
                  } = adjustment;
                  return {
                    id,
                    adjAmount,
                    checkDate: checkDate
                      ? this.$options.filters.getTimeDate(
                          checkDate,
                          "MM-DD-YYYY",
                          true,
                          null
                        ).utc
                      : "",
                    checkNumber,
                    paidAmount,
                    revenueCode: {
                      id: revenueCode.id,
                      code: revenueCode.code,
                    },
                    isLocallyAdded: false,
                  };
                })
              : [],
          }));

          this.summaryCopy = JSON.parse(JSON.stringify(this.summary));
          this.transactionsCopy = JSON.parse(JSON.stringify(this.transactions));
          this.totalCount = transactions[0]?.totalCount || 0;
        } else {
          this.showNotifyMessage({
            message: "No data found.",
            type: "danger",
          });
        }
        this.loading(false);
      } catch (err) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
        this.loading(false);
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async onDiscardDraft() {
      if (!this.isDisabled) {
        this.isDisabled = true;
        const params = {
          id: {
            type: "UUID!",
            value: this.$route.params.id,
            unincludeToFields: true,
          },
          limitData: ["success", "errors"],
          shouldNotReturnId: true,
        };
        try {
          const { success, errors } = await actions.discardDraftClientInvoice(
            params
          );
          if (!success || errors?.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while discarding drafted client invoice.";
            throw errorMesssage;
          }
          this.showNotifyMessage({
            message: "Client invoice discarded.",
            type: "success",
          });
          this.isDisabled = false;
          this.modalVisibility.draftCard = false;

          this.initialDataLoading = true;
          await this.fetchTransactions();
          this.initialDataLoading = false;
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.isDisabled = false;
        }
      }
    },
    onDiscardAdjustments(id = null) {
      if (id) {
        const defaultList = JSON.parse(JSON.stringify(this.transactionsCopy));
        const itemIndex = defaultList.findIndex(item => item.id === id);
        this.transactions = this.transactions.map(transaction => {
          if (transaction.id === id) return { ...defaultList[itemIndex] };
          return { ...transaction };
        });
        this.updateAdjustments(defaultList[itemIndex], true);
      } else {
        this.transactions = JSON.parse(JSON.stringify(this.transactionsCopy));
        this.summary = JSON.parse(JSON.stringify(this.summaryCopy));
      }
      this.modalVisibility.discardAdjustment = false;
    },
    onCurrencyFieldClick(value) {
      this.transaction = value;
      this.modalVisibility.adjustment = true;
    },
    async onApplyAdjustments() {
      try {
        this.isSaving = true;

        const adjustments = this.transactions
          .filter(({ adjustments }) => adjustments.length)
          .map(txn => {
            const adjustmentsToApply = txn.adjustments
              .filter(adj => adj.isLocallyAdded && adj.paidAmount.length || adj.adjAmount.length)
              .map(({
                adjAmount,
                checkDate,
                checkNumber,
                paidAmount,
                revenueCode,
              }) => ({
                clientInvoiceTransactionId: txn.id,
                adjAmount: parseFloat(adjAmount),
                paidAmount: parseFloat(paidAmount),
                checkDate: checkDate
                    ? this.$options.filters.getTimeDate(checkDate, "YYYY-MM-DD HH:mm:ss", false, null).utc
                    : "",
                checkNumber,
                revenueCodeId: revenueCode?.id,
              }));
            if (adjustmentsToApply.length) return adjustmentsToApply;
          });

        const adjustmentsArr = [];
        adjustments
          .filter(adj => adj)
          .forEach(txn => 
            txn.forEach(adj =>
              adjustmentsArr.push(adj)
            ));

        const result = await actions.applyClientInvoiceTransactionAdjustment({
          variables: {
            clientInvoiceId: {
              type: "UUID!",
              value: this.$route.params.id,
              unincludeToFields: true,
            },
            adjustments: {
              type: "[VbsClientInvoiceTransactionAdjustmentInput!]!",
              value: adjustmentsArr,
              unincludeToFields: true,
            },
          },
          limitData: ["success", "errors"],
        });

        if (!result.success) {
          this.showNotifyMessage({
            message: result.errors[0],
            type: "danger",
          });
        } else {
          this.showNotifyMessage({
            message: "Record has been saved successfully!",
            type: "success",
          });

          await this.fetchTransactions();
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "A problem has occured while saving data.",
          type: "danger",
        });
      } finally {
        this.isSaving = false;
        this.modalVisibility.applyAdjustment = false;
      }
    },
    async getInvoicePreview(adjustments) {
      const result = await actions.previewClientInvoiceTransactionAdjustment({
        variables: {
          property: {
            clientInvoiceId: {
              type: "UUID!",
              value: this.$route.params.id,
              unincludeToFields: true,
            },
            adjustments: {
              type: "[VbsClientInvoiceTransactionAdjustmentInput!]!",
              value: adjustments,
              unincludeToFields: true,
            },
          },
        },
        limitData: [
          "totalBilled",
          "totalPaid",
          "totalAdjustment",
          "currentBalance",
          this.buildSubQuery("transactions", [
            "billedAmount",
            "paidAmount",
            "adjAmount",
            "balance",
          ]),
        ],
      });
      return result;
    },
    async updateAdjustments(transaction) {
      const findIndex = this.transactions.findIndex(i => i.id == transaction.id);
      const findIndexCopy = this.transactionsCopy.findIndex(i => i.id == transaction.id);

      if (findIndex > -1) {
        this.transactions[findIndex].adjustments = transaction.adjustments;
        const previewAdjustments = [];
        this.transactions
          .filter(({ adjustments }) => adjustments.length > 0)
          .forEach((trans) => {
            trans.adjustments
              .filter(adj => adj.isLocallyAdded)
              .forEach(item => previewAdjustments.push({
                clientInvoiceTransactionId: transaction.id,
                paidAmount: parseFloat(item.paidAmount) || 0,
                adjAmount: parseFloat(item.adjAmount) || 0,
                revenueCodeId: item.revenueCode.id,
                checkNumber: item.checkNumber,
                checkDate: item.checkDate
                  ? this.$options.filters.getTimeDate(
                      item.checkDate,
                      "YYYY-MM-DD HH:mm:ss",
                      false,
                      null,
                      this.getTimezoneOffset({ date: item.checkDate })
                    ).utc
                  : "",
              }));
          });
        const summaryResult = await this.getInvoicePreview(previewAdjustments);
        const previewResult = await this.getInvoicePreview(this.transactions[findIndex].adjustments
          .filter(adj => adj.isLocallyAdded)
          .map(item => ({
              clientInvoiceTransactionId: transaction.id,
              paidAmount: parseFloat(item.paidAmount) || 0,
              adjAmount: parseFloat(item.adjAmount) || 0,
              revenueCodeId: item.revenueCode.id,
              checkNumber: item.checkNumber,
              checkDate: item.checkDate
                ? this.$options.filters.getTimeDate(
                    item.checkDate,
                    "YYYY-MM-DD HH:mm:ss",
                    false,
                    null,
                    this.getTimezoneOffset({ date: item.checkDate })
                  ).utc
                : "",
          })));
        const { totalBilled, totalPaid, totalAdjustment, currentBalance } = summaryResult;
        const findTransaction = previewResult.transactions?.length
          ? previewResult.transactions.find((item) => item.id == transaction.id)
          : null;
        const objKeys = [
          "billedAmount",
          "paidAmount",
          "adjAmount",
          "balance",
          "revenueCode",
          "checkDetails",
          "checkDate",
        ];
        if (findTransaction) {
          for (let key of objKeys) {
            const keyUpperCase = key.toUpperCase();
            if (keyUpperCase == "BILLEDAMOUNT" || keyUpperCase == "BALANCE") {
              this.transactions[findIndex][key] =  this.getFormattedAmount(findTransaction[key]);
            } else if (
              keyUpperCase == "REVENUECODE" ||
              keyUpperCase == "CHECKDETAILS" ||
              keyUpperCase == "CHECKDATE"
            ) {
              const lastItem = transaction.adjustments.findLast((item) => item);
              if (keyUpperCase == "REVENUECODE")
                this.transactions[findIndex][key] =
                  typeof lastItem.revenueCode == "string"
                    ? lastItem.revenueCode
                    : lastItem.revenueCode.code;
              else if (keyUpperCase == "CHECKDATE")
                this.transactions[findIndex].transactionDate =
                  this.$options.filters.getTimeDate(
                    new Date(),
                    "MM/DD/YYYY"
                  ).zone;
              else
                this.transactions[findIndex][key] =
                  lastItem?.checkNumber ||
                  this.transactionsCopy[findIndexCopy][key];
            } else {
              this.transactions[findIndex][key] = findTransaction[key] || "";
            }
          }
        }

        this.updateSummary({
          totalBilled,
          totalPaid,
          totalAdjustment,
          currentBalance,
        });
      }
    },
    updateSummary(summary) {
      for (let key of Object.keys(summary)) {
        const findIndex = this.summary.cols.findIndex(
          (item) => item.key == key
        );

        if (findIndex > -1)
          this.summary.cols[findIndex].data = this.getFormattedAmount(
            summary[key]
          );
      }
    },

    async generateInvoice() {
      try {
        this.loading(true);
        const result = await actions.generateClientInvoice({
          variables: {
            clientInvoiceId: {
              type: "UUID!",
              value: this.$route.params.id,
              unincludeToFields: true,
            },
          },
          limitData: ["success", "entityId", "code", "errors"],
        });
        if (result.success) {
          this.showNotifyMessage({
            message: "Generate Invoice successfully",
            type: "success",
          });
          await this.fetchTransactions();
        } else {
          this.showNotifyMessage({
            message: "A problem has occured while generating client invoice",
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message: "A problem has occured while generating client invoice",
          type: "danger",
        });
      } finally {
        this.loading(false);
      }
    },
    async viewDraftInvoice() {
      window.open(
        `/system-maintenance/clients-physicians/clients/invoice/${this.$route.params.id}/preview-invoice`
      );
    },
    getFormattedAmount(amount) {
      if (amount === 0) return "$ 0.00";
      return this.formatCurrency(amount, 2, "$");
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetchTransactions();
      this.initialDataLoading = false;
    });
  },
};
