import RevenueCode from "store/models/RevenueCode";
import StoreActions from "store/actions";

const storeActions = new StoreActions(RevenueCode);

export default class RevenueCodeAction {
  async getAll(variables, desciption = "") {
    return await storeActions.query(
      {
        queryName: "vbsRevenueCodes",
        relativeUrl: "/revenue-code/get-all-revenue-code",
        ...variables,
      },
      desciption.length ? desciption : "to load revenue codes list."
    );
  }

  async get(params) {
    const { limitData, variables } = params;
    return await storeActions.query(
      {
        queryName: "getVbsRevenueCode",
        relativeUrl: "/revenue-code/get-revenue-code",
        limitData: limitData || null,
      },
      "To fetch revenue code details.",
      {
        ...variables,
      }
    );
  }

  async create(variables) {
    const { limitData, input } = variables;
    return await storeActions.mutation(
      {
        queryName: "createVbsRevenueCode",
        relativeUrl: "/revenue-code/create-revenue-code",
        shouldNotReturnId: true,
        limitData: limitData || null,
      },
      "To create revenue code.",
      { input }
    );
  }

  async update(params) {
    const { queries, variables, collectionQueries } = params;
    return await storeActions.mutation(
      {
        queryName: "updateVbsRevenueCode",
        relativeUrl: "/revenue-code/update-revenue-code",
        queries: queries || null,
        collectionQueries: collectionQueries || [],
      },
      "To update revenue code..",
      {
        ...variables,
      }
    );
  }

  async getCodes(params) {
    const { limitData, variables } = params;
    return await storeActions.query(
      {
        queryName: "vbsCodes",
        relativeUrl: "/revenue-code/get-codes",
        shouldNotReturnId: true,
        limitData: limitData || null,
      },
      "To load codes.",
      variables
    );
  }

  async activate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation(
      {
        queryName: "activateVbsRevenueCode",
        relativeUrl: "/revenue-code/activate",
        limitData,
        shouldNotReturnId,
      },
      "To activate revenue code.",
      {
        id,
      }
    );
  }

  async deactivate(variables) {
    const { id, limitData = [], shouldNotReturnId = false } = variables;
    return await storeActions.mutation(
      {
        queryName: "deactivateVbsRevenueCode",
        relativeUrl: "/revenue-code/deactivate",
        limitData,
        shouldNotReturnId,
      },
      "To deactivate revenue code.",
      {
        id,
      }
    );
  }
}
